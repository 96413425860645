.mySwiper-container {
  .swiper-button-disabled {
    cursor: not-allowed !important;
  }

  .swiper-button-new-prev,
  .swiper-button-new-next {
    position: absolute;
    top: 90%;
    bottom: 0 !important;

    z-index: 10;
    cursor: pointer;
    color: #fff;
    font-size: 24px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 50%;
  }

  .swiper-button-new-prev {
    left: 47%;
  }

  .swiper-button-new-next {
    right: 47%;
  }
}

@media only screen and (min-width: 1100px) and (max-width: 1350px) {
  .mySwiper-container {
    .swiper-button-new-prev {
      left: 44%;
    }

    .swiper-button-new-next {
      right: 44%;
    }
  }
}

@media only screen and (max-width: 1100px) {
  .mySwiper-container {
    .swiper-button-new-prev {
      left: 45%;
    }

    .swiper-button-new-next {
      right: 45%;
    }
  }
}

@media only screen and (max-width: 768px) {
  .mySwiper-container {
    .swiper-button-new-prev {
      left: 40% !important;
    }

    .swiper-button-new-next {
      right: 40% !important;
    }
  }
}
@media only screen and (max-width: 576px) {
  .mySwiper-container {
    .swiper-button-new-prev {
      left: 30% !important;
      margin-right: 20px;
    }

    .swiper-button-new-prev {
      right: 30% !important;
      margin-left: 20px;
    }
  }
}

.mySwiper {
  position: relative;

  .swiper-slide {
    display: flex;
    height: 368px !important;
    min-height: 100%;
    align-items: center;
    justify-content: center;
    border-radius: 18px;
    font-size: 22px;
    font-weight: bold;
    color: #fff;
    background-size: cover;
  }

  .swiper-slide:nth-child(1n) {
    background-color: rgb(0, 140, 255);
    background-repeat: no-repeat;
    // background-image: url(../img/red-slider-img.svg);
  }

  .swiper-slide:nth-child(2n) {
    background-color: rgba(245, 86, 88, 1);
    background-repeat: no-repeat;
    // background-image: url(../img/slider-img.svg);
  }

  .swiper-slide:nth-child(3n) {
    background-color: rgba(250, 190, 36, 1);
    background-repeat: no-repeat;
    // background-image: url(../img/yellow-img.svg);
  }
  .swiper-slide:nth-child(4n) {
    background-color: rgb(0, 140, 255);
    background-repeat: no-repeat;
    // background-image: url(../img/img-1.svg);
  }
  .swiper-slide:nth-child(5n) {
    background-color: rgba(245, 86, 88, 1);
    background-repeat: no-repeat;
    // background-image: url(../img/img-2.svg);
  }
  .swiper-slide:nth-child(6n) {
    background-color: rgba(250, 190, 36, 1);
    // background-image: url(../img/gps-img.svg);
    // background-repeat: no-repeat;
  }
  .swiper-slide:nth-child(7n) {
    background-color: rgb(0, 140, 255);
    background-repeat: no-repeat;
    // background-image: url(../img/img-3.svg);
  }
  .swiper-slide:nth-child(8n) {
    background-color: rgba(245, 86, 88, 1);
    background-repeat: no-repeat;
    // background-image: url(../img/img-4.svg);
  }
  .swiper-slide:nth-child(9n) {
    background-color: rgba(250, 190, 36, 1);
    background-repeat: no-repeat;
    // background-image: url(../img/img-5.svg);
  }
}

@media only screen and (max-width: 1024px) {
  .mySwiper {
    max-width: 100% !important;
    width: 500px;
    // height: 300px;
  }
}

@media only screen and (max-width: 768px) {
  .mySwiper {
    max-width: 100% !important;
    width: 300px;
    // height: 200px;
  }
}

// Mobile Slider

.myMobileSwiper {
  // width: 80%;
  padding-top: 20px;
  padding-bottom: 20px;

  .swiper-slide-active img {
    position: relative;
    top: -6px;

    border-radius: 20px !important;
    height: 560px !important;
  }

  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 300px;
    filter: blur(2px);
    position: relative;

    .swiper-slide-shadow-coverflow {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .swiper-slide-active {
    filter: blur(0px);
  }

  .swiper-slide img {
    // display: block;
    width: 100%;
    position: relative;
  }

  .swiper-3d .swiper-slide-shadow-left {
    background-image: none;
  }
  .swiper-3d .swiper-slide-shadow-right {
    background-image: none;
  }
}
@media screen and (max-width: 767px) {
  .myMobileSwiper {
    width: 95%;
    .swiper-slide {
      // width: 200px !important;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .myMobileSwiper {
    width: 85%;
  }
}

@media screen and (min-width: 1024px) {
  .myMobileSwiper {
    width: 75%;
  }
}

@media screen and (max-width: 579px) {
  .myMobileSwiper .swiper-slide img {
    top: -17px !important;
  }
}

// New Css

.m-container {
  position: relative;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;

  .mobile-container {
    position: relative;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
  }

  .mobile-image {
    width: 100%;
    height: auto;
    display: block;
  }

  .swiper-containerr {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    img {
      max-width: 100%;
      height: auto;
    }
  }
}

#screenshots {
  .swiper-button-disabled {
    cursor: not-allowed !important;
  }

  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    top: 60%;
    transform: translateY(-50%);
    z-index: 10;
    cursor: pointer;
    color: #fff;
    font-size: 24px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
  }

  .swiper-button-prev {
    left: 33%;
  }

  .swiper-button-next {
    right: 33%;
  }
}

@media only screen and (max-width: 1000px) {
  #screenshots {
    .swiper-button-prev,
    .swiper-button-next {
      position: absolute;
      top: 96%;
      bottom: 0 !important;
      // transform: translateY(-50%);
      z-index: 10;
      cursor: pointer;
      color: #fff;
      font-size: 24px;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 50%;
    }

    .swiper-button-prev {
      left: 45% !important;
      margin-right: 20px;
    }

    .swiper-button-next {
      right: 45% !important;
      margin-left: 20px;
    }
  }
}

@media only screen and (max-width: 768px) {
  #screenshots {
    .swiper-button-prev {
      left: 42% !important;
      margin-right: 20px;
    }

    .swiper-button-next {
      right: 42% !important;
      margin-left: 20px;
    }
  }
}
@media only screen and (max-width: 576px) {
  #screenshots {
    .swiper-button-prev {
      left: 37% !important;
      margin-right: 20px;
    }

    .swiper-button-next {
      right: 37% !important;
      margin-left: 20px;
    }
  }
}
