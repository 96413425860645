@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

/* Font - Bold */
@font-face {
  font-family: "Mona-Bold";
  src: local("Mona-Bold"),
    url("./assets/fonts/Mona-Sans-ExtraBold.ttf") format("truetype");
  /* Add additional formats if needed */
}

/* Font - Medium */
@font-face {
  font-family: "Mona-Medium";
  src: local("Mona-Medium"),
    url("./assets/fonts/Mona-Sans-Medium.ttf") format("truetype");
}

/* Font - Regular */
@font-face {
  font-family: "Mona-Regular";
  src: local("Mona-Regular"),
    url("./assets/fonts/Mona-Sans-Regular.ttf") format("truetype");
}

@layer utilities {
  .radius {
    @apply rounded-[10px] !important;
  }
  .flex-center {
    @apply flex items-center justify-center !important;
  }
  .filled-button {
    @apply bg-primary md:text-base text-lg hover:bg-primary/85 border-none rounded-[5px] font-monaRegular font-medium transition-all tracking-wider !important;
  }

  .gray-button {
    @apply bg-gray text-sm hover:bg-gray/85 border-none rounded-[10px] font-popinsMedium tracking-wider !important;
  }
  .default-button {
    @apply outline-0 text-sm  border-none rounded-[10px] font-popinsMedium tracking-wider !important;
  }

  .page-title {
    @apply font-monaRegular font-semibold lg:text-[40px] md:text-3xl text-[28px] tracking-wider text-secondary;
  }
  .page-desc {
    @apply font-monaRegular font-normal  lg:text-[18px] md:text-[18px] text-base text-[#6B6B6B] tracking-wide text-center;
  }

  .input {
    &::placeholder {
      @apply text-sm !important;
    }
    @apply  text-sm placeholder:text-sm w-full h-[45px] rounded-md border border-gray-400 px-3 shadow-sm outline-none  ring-0;
  }
}

* {
  font-family: "Mona-Regular" !important;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
