// Header
nav {
  box-shadow: 0px 4px 20px 0px #bc5a001f;
  border-radius: 10px;
}

.centered-axis-xy {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bg-img {
  background-image: url(../img/image2.svg);
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}

.about-us {
  background-image: url(../img/about-us.svg);
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}
.sticky-navbar {
  position: fixed !important;
  left: 50% !important;
  z-index: 9999 !important;
  top: 5px !important;
  padding-top: 10px !important;
  margin: 10px 0;
  transform: translateX(-50%);
}

:root {
  --react-international-phone-height: 45px !important;
  --react-international-phone-border-radius: 6px;
  --react-international-phone-font-size: 14px;
  --react-international-phone-border-color: rgb(156 163 175);
  --react-international-phone-text-color: black;
}

.react-international-phone-input-container .react-international-phone-input {
  overflow: visible;
  height: var(--react-international-phone-height, 45px) !important;
  box-sizing: border-box;
  width: 100%;
}

.react-international-phone-country-selector {
  .react-international-phone-country-selector-button {
    padding: 0 10px !important;
  }
  height: 45px !important;
}
/* 
:where(html[dir="ltr"]),
:where([data-sonner-toaster][dir="ltr"]) {
  --toast-close-button-start: unset !important;
  --toast-close-button-end: unset !important;
} */

:where(html[dir="ltr"]),
:where([data-sonner-toaster][dir="ltr"]) {
  --toast-icon-margin-start: -3px;
  --toast-icon-margin-end: 4px;
  --toast-svg-margin-start: -1px;
  --toast-svg-margin-end: 0px;
  --toast-button-margin-start: auto;
  --toast-button-margin-end: 0;
  --toast-close-button-start: unset !important;
  --toast-close-button-end: unset !important;
  --toast-close-button-transform: translate(-35%, -35%);
}

li[data-sonner-toast] {
  padding: 16px !important;
  button {
    position: absolute;
    top: 50%;
    right: 0 !important;
    transform: translate(-50%, -50%);
    svg {
      width: 16px;
      height: 16px;
    }
  }
}
